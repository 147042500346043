import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';

import Error from '../images/404-error.svg';

import { BackToHomePageButton } from './BackToHomePageButton';

export const Error404Content = () => {
  const { t } = useTranslation('errors');

  return (
    <main className={cn('xl:mx-auto', 'xl:max-w-7xl')}>
      <div className={cn('flex', 'flex-col', 'items-center')}>
        <Error className={cn('w-[400px]', 'h-[320px]', 'text-gray-500')} />
        <p className={cn('mb-10', 'text-primary-900', 'text-base')}>
          {t('There seems to be no such site.')}
        </p>
        <BackToHomePageButton />
      </div>
    </main>
  );
};
